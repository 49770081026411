<template>
  <el-select
    v-model="value"
    clearable
    size="large"
    filterable
    placeholder="Select skills"
    class="w-100"
  >
    <el-option
      v-for="item in skills"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "Job-Create-Skills-Dropdown",
  data() {
    return {
      value: null
    };
  },
  props: {
    clientId: {
      type: [Number, null],
      default: () => null
    }
  },
  methods: {
    ...mapActions("JobCreateModule", [
      "fetchSkills",
      "fetchFrequentlyUsedSkills"
    ]),
    setSkills() {
      if (this.skills && this.skills[0] && this.skills[0].id == "freq_1") {
        this.value = this.skills[0].id;
        this.$emit("change", this.skills[0].id);
      } else {
        if (this.value == "freq_1") {
          this.value = null; //clearing the value to avoid a bug that was caused when
        } //the user select freq.skills and then changes job company
      }
    },
    setFreqSkills() {
      this.fetchFrequentlyUsedSkills(this.clientId).then(() => {
        this.setSkills();
      });
    }
  },
  mounted() {
    if (this.clientId) {
      if (this.skills.length) {
        this.setFreqSkills();
      } else {
        this.fetchSkills().then(() => {
          this.setFreqSkills();
        });
      }
    } else {
      this.fetchSkills();
    }
  },
  watch: {
    skills: {
      handler() {
        this.setSkills();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("JobCreateModule", ["skills"])
  }
});
</script>
