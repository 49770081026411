
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "DeleteButtonIcon",
  props: {
    dynamicClass: {
      type: String as PropType<string>,
      required: false
    },
    svgSize: {
      type: String as PropType<string>,
      required: false
    }
  }
});
