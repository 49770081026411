<template>
  <el-select
    v-model="value"
    clearable
    :disabled="isDisabled"
    filterable
    :size="size"
    placeholder="Select currency"
    class="w-100"
  >
    <el-option
      v-for="currency in getCurrencies"
      :key="currency.id"
      :label="`(${currency.code}) ${currency.name}`"
      :value="currency.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "currencies-dropdown",
  props: {
    isDisabled: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      value: "",
      size: "large"
    };
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCurrencies"])
  },
  mounted() {
    this.fetchCurrencies();
  },
  computed: {
    ...mapGetters("ListsModule", ["getCurrencies"])
  }
});
</script>
<style>
.el-input__inner {
  height: 40px !important;
}
</style>
