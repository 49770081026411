
import { Form, Field, ErrorMessage } from "vee-validate";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref
} from "vue";
import SkillsDropdown from "@/components/dropdown/JobCreateSkillsDropdown.vue";
import { useStore } from "vuex";
import { fireToast } from "@/core/helpers/swal";
import { useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import DeleteButtonIcon from "@/components/Icons/DeleteButton.vue";
import CurrenciesDropdown from "@/components/dropdown/CurrenciesDropdown.vue";

export interface QuoteSkill {
  id: number;
  qty: number;
  name: string;
  price: number;
}
export default defineComponent({
  name: "create-quote-view",
  components: {
    CurrenciesDropdown,
    DeleteButtonIcon,
    SkillsDropdown,
    ErrorMessage,
    Form,
    Field
  },
  setup: function() {
    const store = useStore();
    const subSkills = ref([]);
    const formComponent = ref();
    const router = useRouter();
    const skills = computed(() => store.getters["ListsModule/skills"]);
    const quoteSkill = ref<QuoteSkill[]>([]);
    const saving = ref<boolean>(false);
    const editing = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const route = useRoute();
    const quoteData = reactive({
      company: null,
      address: "",
      vat: "",
      title: "",
      unit: "",
      currency: null,
      notes: ""
    });
    const models = ref([] as any[]);

    function setSaving(state: boolean): void {
      saving.value = state;
    }

    const companies = computed(
      () => store.getters["ListsModule/companiesDropdown"]
    );

    function handleEditing(id) {
      loading.value = true;
      store
        .dispatch("JobQuotesModule/getQuoteById", id)
        .then(data => {
          quoteData.unit = data.unit;
          quoteData.vat = data.unit;
          quoteData.address = data.address;
          quoteData.title = data.title;
          quoteData.currency = data.currency_id;
          quoteData.notes = data.notes;
          quoteData.company = data.company_id ? data.company_id : data.company;
          quoteSkill.value = JSON.parse(data.skills);
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          router.push("job-quotes");
        });
    }

    onBeforeMount((): void => {
      const route = useRoute();
      if (route.params.id) {
        editing.value = true;
        handleEditing(route.params.id);
      } else {
        editing.value = false;
      }
    });

    onMounted((): void => {
      store.dispatch("ListsModule/fetchSkills");
      if (companies.value.length == 0) {
        store.dispatch("ListsModule/fetchCompaniesDropdown");
      }
    });

    function onSkillChange(skillId: number): void {
      models.value = [];
      const skill = skills.value.find(skill => skill.id == skillId);
      if (skill && skill.children) {
        subSkills.value = skill.children;
      } else {
        subSkills.value = [];
      }
    }

    function handleChange(value: number, skillId: number, name: string): void {
      models.value[skillId] = {
        name: name,
        qty: value,
        id: skillId
      };
    }

    function handleAddSkills() {
      formComponent.value.setFieldError("skills", undefined);
      models.value.forEach(el => {
        if (parseInt(el.qty) > 0) {
          quoteSkill.value.push({
            name: el.name,
            qty: parseInt(el.qty),
            id: el.id,
            price: 0
          });
        }
      });
    }

    function handleSubmit(data): void {
      if (quoteSkill.value.length == 0) {
        formComponent.value.setFieldError(
          "skills",
          "Select at least one skill"
        );
        return;
      }
      setSaving(true);
      const payload = {
        ...quoteData,
        skills: quoteSkill.value
      };
      if (editing.value) {
        payload["id"] = route.params.id;
        payload["editing"] = true;
      }
      store
        .dispatch("JobQuotesModule/saveQuote", payload)
        .then(() => {
          router.push("/job-quotes").then(() => {
            fireToast("Quote saved successfully", true);
          });
        })
        .catch(() => {
          fireToast("Error while saving quote", false);
        });
    }

    const validationSchema = Yup.object().shape({
      // eslint-disable-next-line @typescript-eslint/camelcase
      quote_title: Yup.string()
        .required()
        .label("Title")
    });

    function handleDeleteSkill(index: number): void {
      quoteSkill.value.splice(index, 1);
    }

    function handleClientSelect() {
      if (typeof quoteData.company === "number") {
        store
          .dispatch("JobQuotesModule/getClientData", {
            company: quoteData.company
          })
          .then(data => {
            quoteData.vat = data.vat;
            quoteData.address = data.address;
            if (data.preferredSkills.length) {
              subSkills.value = data.preferredSkills;
            }
          })
          .catch(() => {
            quoteData.vat = "";
            quoteData.address = "";
          });
      } else {
        quoteData.vat = "";
        quoteData.address = "";
      }
    }

    return {
      handleClientSelect,
      handleDeleteSkill,
      validationSchema,
      handleAddSkills,
      onSkillChange,
      formComponent,
      handleChange,
      handleSubmit,
      quoteSkill,
      companies,
      subSkills,
      quoteData,
      loading,
      editing,
      saving,
      models
    };
  }
});
